<template>
  <div
    class="navbar-burger burger"
    :class="{ active: hamburgerState }"
    @click.prevent="onClick"
  >
    <a
      id="navbar-burger-id"
      role="button"
      class="navbar-burger burger is-hoverable"
    >
      <span></span>
      <span></span>
      <span></span>
    </a>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "hamburger",
  props: {
    externalState: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    let hamburgerState = this.externalState;
    return {
      hamburgerState
    };
  },
  methods: {
    onClick(event) {
      this.hamburgerState = !this.hamburgerState;
      this.$emit("hamburger-state", this.hamburgerState);
    }
  }
});
</script>

