<template>
  <div class="container">
    <header class="header">
      <nav class="navbar is-transparent">
        <div class="navbar-brand">
          <strong>
            <g-link to="/" class="is-size-3">{{
              $static.metadata.siteName
            }}</g-link>
          </strong>
          <Hamburger
            :externalState="navbarState"
            v-on:hamburger-state="hamburgerClicked($event)"
          ></Hamburger>
        </div>

        <div
          id="siteNavbar"
          class="navbar-menu"
          v-bind:class="{ 'is-active': navbarState }"
        >
          <div class="navbar-start">
            <g-link class="nav__link navbar-item" to="/">Home&emsp;</g-link>
            <!-- <g-link class="nav__link navbar-item" to="/about/">About&emsp;</g-link> -->
            <g-link class="nav__link navbar-item" to="/publications/"
              >Publications</g-link
            >
          </div>
        </div>
      </nav>
    </header>
    <body>
      <slot />
    </body>
  </div>
</template>

<static-query>
query {
  metadata {
    siteName
  }
}
</static-query>

<style lang="sass">
@import "../styles/bulma-settings.scss"
</style>

<script>
import Vue from "vue";
import Hamburger from "../components/Hamburger.vue";

export default Vue.extend({
  name: "DefaultLayout",
  components: {
    Hamburger
  },
  data() {
    return {
      navbarState: false
    };
  },
  methods: {
    hamburgerClicked(value) {
      this.navbarState = value;
    }
  }
});
</script>
