export default [
  {
    path: "/publications/",
    component: () => import(/* webpackChunkName: "page--src--pages--publications-vue" */ "/opt/build/repo/src/pages/Publications.vue")
  },
  {
    name: "404",
    path: "/404/",
    component: () => import(/* webpackChunkName: "page--src--pages--404-vue" */ "/opt/build/repo/src/pages/404.vue")
  },
  {
    name: "home",
    path: "/",
    component: () => import(/* webpackChunkName: "page--src--pages--index-vue" */ "/opt/build/repo/src/pages/Index.vue")
  },
  {
    name: "*",
    path: "*",
    component: () => import(/* webpackChunkName: "page--src--pages--404-vue" */ "/opt/build/repo/src/pages/404.vue")
  }
]

